// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.button-17 {
    padding: 1.3em 3em;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 2.5px;
    font-weight: 500;
    color: #000;
    background-color: #fff;
    border: none;
    border-radius: 45px;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease 0s;
    cursor: pointer;
    outline: none;
  }
  
  .button-17:hover {
    background-color: #3985CB;
    box-shadow: 0px 15px 20px rgba(2, 129, 255, 0.4);
    color: #fff;
    transform: translateY(-7px);
  }
  
  .button-17:active {
    transform: translateY(-1px);
  }`, "",{"version":3,"sources":["webpack://./src/components/Start/button.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,eAAe;IACf,yBAAyB;IACzB,qBAAqB;IACrB,gBAAgB;IAChB,WAAW;IACX,sBAAsB;IACtB,YAAY;IACZ,mBAAmB;IACnB,2CAA2C;IAC3C,4BAA4B;IAC5B,eAAe;IACf,aAAa;EACf;;EAEA;IACE,yBAAyB;IACzB,gDAAgD;IAChD,WAAW;IACX,2BAA2B;EAC7B;;EAEA;IACE,2BAA2B;EAC7B","sourcesContent":[".button-17 {\n    padding: 1.3em 3em;\n    font-size: 12px;\n    text-transform: uppercase;\n    letter-spacing: 2.5px;\n    font-weight: 500;\n    color: #000;\n    background-color: #fff;\n    border: none;\n    border-radius: 45px;\n    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);\n    transition: all 0.3s ease 0s;\n    cursor: pointer;\n    outline: none;\n  }\n  \n  .button-17:hover {\n    background-color: #3985CB;\n    box-shadow: 0px 15px 20px rgba(2, 129, 255, 0.4);\n    color: #fff;\n    transform: translateY(-7px);\n  }\n  \n  .button-17:active {\n    transform: translateY(-1px);\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
