import React from "react";

export const OrganizingCommittee = () => {
  return (
    <>
      <div className="section-header">
        <h2>Comité Organizador</h2>
        <p>Profesionales que se encargan de la organización del evento.</p>
      </div>
      <div className="boxCard centerBoxView mb-5">
        <div className="poderver flex-column container p-3">
          <ul className="flex flex-wrap">
            <li className="listStyle">Dr. Mario Daniel</li>
            <li className="listStyle">Prof. Mónica Barrera</li>
            <li className="listStyle">Ing. Marianela de Carmen Bignert</li>
            <li className="listStyle">Prof. Vanesa Belén Iglesias</li>
          </ul>
        </div>
      </div>
    </>
  );
};
