// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.listStyle {
  font-size: 1.3rem;
  width: 100%;
  padding: 0.15em;
}

.centerBoxView {
  margin: 0 auto;
  width: 50%;
}

@media (max-width: 960px) {
  .centerBoxView {
    width: 80%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/views/ScientificCommittee/ScientificCommittee.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,WAAW;EACX,eAAe;AACjB;;AAEA;EACE,cAAc;EACd,UAAU;AACZ;;AAEA;EACE;IACE,UAAU;EACZ;AACF","sourcesContent":[".listStyle {\n  font-size: 1.3rem;\n  width: 100%;\n  padding: 0.15em;\n}\n\n.centerBoxView {\n  margin: 0 auto;\n  width: 50%;\n}\n\n@media (max-width: 960px) {\n  .centerBoxView {\n    width: 80%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
