// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.box-cardJob {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin: 0.5rem 5rem;
}
.cardbody-job {
  display: flex;
  flex-direction: column;
  align-items: center;
}
/* .box-items-job {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
} */
.btn.disabled {
  pointer-events: auto;
}
.box-send-job {
  height: 100%;
  border-radius: 1rem;
  box-shadow: 9px 9px 30px 1px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 9px 9px 30px 1px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 9px 9px 30px 1px rgba(0, 0, 0, 0.2);
  background-color: #ffff;
}
.box-status-correction {
  height: 70px;
  width: 70px;
  border-radius: 1rem;
  /*   background-color: #d45c5c; */
}

.title-evaluator-correction {
  font-size: 18px;
  font-weight: 600;
  color: #2864f6;
}
.name-evaluator-correction {
  font-size: 18px;
  font-weight: 600;
}

.box-correction-detail {
  max-height: 250px;
  overflow: auto;
  padding: 10px;
}

.align-uploadjob-button {
  width: -moz-fit-content;
  width: fit-content;
  margin-left: 85%;
}`, "",{"version":3,"sources":["webpack://./src/components/Jobs/jobs.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,6BAA6B;EAC7B,eAAe;EACf,mBAAmB;AACrB;AACA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;AACrB;AACA;;;;GAIG;AACH;EACE,oBAAoB;AACtB;AACA;EACE,YAAY;EACZ,mBAAmB;EACnB,+CAA+C;EAC/C,uDAAuD;EACvD,oDAAoD;EACpD,uBAAuB;AACzB;AACA;EACE,YAAY;EACZ,WAAW;EACX,mBAAmB;EACnB,iCAAiC;AACnC;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,cAAc;AAChB;AACA;EACE,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,iBAAiB;EACjB,cAAc;EACd,aAAa;AACf;;AAEA;EACE,uBAAkB;EAAlB,kBAAkB;EAClB,gBAAgB;AAClB","sourcesContent":[".box-cardJob {\n  display: flex;\n  justify-content: space-around;\n  flex-wrap: wrap;\n  margin: 0.5rem 5rem;\n}\n.cardbody-job {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n/* .box-items-job {\n  display: flex;\n  justify-content: center;\n  flex-wrap: wrap;\n} */\n.btn.disabled {\n  pointer-events: auto;\n}\n.box-send-job {\n  height: 100%;\n  border-radius: 1rem;\n  box-shadow: 9px 9px 30px 1px rgba(0, 0, 0, 0.2);\n  -webkit-box-shadow: 9px 9px 30px 1px rgba(0, 0, 0, 0.2);\n  -moz-box-shadow: 9px 9px 30px 1px rgba(0, 0, 0, 0.2);\n  background-color: #ffff;\n}\n.box-status-correction {\n  height: 70px;\n  width: 70px;\n  border-radius: 1rem;\n  /*   background-color: #d45c5c; */\n}\n\n.title-evaluator-correction {\n  font-size: 18px;\n  font-weight: 600;\n  color: #2864f6;\n}\n.name-evaluator-correction {\n  font-size: 18px;\n  font-weight: 600;\n}\n\n.box-correction-detail {\n  max-height: 250px;\n  overflow: auto;\n  padding: 10px;\n}\n\n.align-uploadjob-button {\n  width: fit-content;\n  margin-left: 85%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
