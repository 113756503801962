// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pay-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pay-card {
  width: 45rem;
  margin: 0.5rem 0rem;
}

@media (max-width: 960px) {
  .pay-card {
    width: 90vw;
    margin: 0.5rem 0rem;
  }
}

.update-invoice {
  font-size: 1.25rem;
  padding: 2rem;
  border-radius: 2rem;
  background-color: white;
  margin-top: 5rem;
}
`, "",{"version":3,"sources":["webpack://./src/components/Payment/pays.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;AACrB;;AAEA;EACE,YAAY;EACZ,mBAAmB;AACrB;;AAEA;EACE;IACE,WAAW;IACX,mBAAmB;EACrB;AACF;;AAEA;EACE,kBAAkB;EAClB,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,gBAAgB;AAClB","sourcesContent":[".pay-container {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n\n.pay-card {\n  width: 45rem;\n  margin: 0.5rem 0rem;\n}\n\n@media (max-width: 960px) {\n  .pay-card {\n    width: 90vw;\n    margin: 0.5rem 0rem;\n  }\n}\n\n.update-invoice {\n  font-size: 1.25rem;\n  padding: 2rem;\n  border-radius: 2rem;\n  background-color: white;\n  margin-top: 5rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
