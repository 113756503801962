// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.col-sidebar{
  background-color: #fff;
  border-right: 1px solid rgb(171, 171, 171);
}`, "",{"version":3,"sources":["webpack://./src/components/Home/home.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,0CAA0C;AAC5C","sourcesContent":[".col-sidebar{\n  background-color: #fff;\n  border-right: 1px solid rgb(171, 171, 171);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
