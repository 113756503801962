// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.account-activate {
  height: 100vh;
}
.activated {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}
.icon-success {
  color: #2fb917;
}

.icon-error {
  color: red;
}
`, "",{"version":3,"sources":["webpack://./src/components/AccountActivate/AccountActivate.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;AACA;EACE,aAAa;EACb,sBAAsB;EACtB,eAAe;AACjB;AACA;EACE,cAAc;AAChB;;AAEA;EACE,UAAU;AACZ","sourcesContent":[".account-activate {\n  height: 100vh;\n}\n.activated {\n  display: flex;\n  flex-direction: column;\n  flex-wrap: wrap;\n}\n.icon-success {\n  color: #2fb917;\n}\n\n.icon-error {\n  color: red;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
