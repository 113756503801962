// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.carouselBox {
  margin: auto;
}

.slick-prev:before {
  color: black;
  font-size: 50px;
}

.slick-next:before {
  color: black;
  font-size: 50px;
}

@media (min-width: 1701px) {
  .carouselBox {
    margin-top: 2rem;
    max-width: 90%;
    max-height: 90vh;
  }
}

@media (max-width: 1700px) {
  .carouselBox {
    margin-top: 2rem;
    max-width: 80%;
    max-height: 80vh;
  }
}

@media (max-width: 500px) {
  .carouselBox {
    width: 100%;
    height: 70%;
  }
  .slick-next:before {
    display: none;
  }
  .slick-prev:before {
    display: none;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/Carousel/Carousel.css"],"names":[],"mappings":"AAAA;EACE,YAAY;AACd;;AAEA;EACE,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE;IACE,gBAAgB;IAChB,cAAc;IACd,gBAAgB;EAClB;AACF;;AAEA;EACE;IACE,gBAAgB;IAChB,cAAc;IACd,gBAAgB;EAClB;AACF;;AAEA;EACE;IACE,WAAW;IACX,WAAW;EACb;EACA;IACE,aAAa;EACf;EACA;IACE,aAAa;EACf;AACF","sourcesContent":[".carouselBox {\n  margin: auto;\n}\n\n.slick-prev:before {\n  color: black;\n  font-size: 50px;\n}\n\n.slick-next:before {\n  color: black;\n  font-size: 50px;\n}\n\n@media (min-width: 1701px) {\n  .carouselBox {\n    margin-top: 2rem;\n    max-width: 90%;\n    max-height: 90vh;\n  }\n}\n\n@media (max-width: 1700px) {\n  .carouselBox {\n    margin-top: 2rem;\n    max-width: 80%;\n    max-height: 80vh;\n  }\n}\n\n@media (max-width: 500px) {\n  .carouselBox {\n    width: 100%;\n    height: 70%;\n  }\n  .slick-next:before {\n    display: none;\n  }\n  .slick-prev:before {\n    display: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
