// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login-view {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
}
.card-login {
  width: 30rem;
  padding-bottom: 1rem;
  border-radius: 10px !important;
}
.logo-login {
  display: flex;
  justify-content: center;
  padding: 16px;
}
.imgLogin {
  width: 20rem;
}
/* .form-control:focus{
    color: #212529;
    background-color: #fff;
    border-color: var(--color-clicap) !important;
    outline: 0;
    box-shadow: 0 0 0 0.1rem var(--color-clicap-focus);
} */

.btn-login {
  width: 100%;
  background-color: var(--color-clicap) !important;
  border-color: var(--color-clicap) !important;
  color: white !important;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
}
.btn-login:hover {
  background-color: var(--color-clicap-hover);
  border-color: var(--color-clicap-hover);
  color: white;
}
.btn-register {
  width: 100%;
  margin-top: 5px;
}
`, "",{"version":3,"sources":["webpack://./src/components/Login/login.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,gBAAgB;AAClB;AACA;EACE,YAAY;EACZ,oBAAoB;EACpB,8BAA8B;AAChC;AACA;EACE,aAAa;EACb,uBAAuB;EACvB,aAAa;AACf;AACA;EACE,YAAY;AACd;AACA;;;;;;GAMG;;AAEH;EACE,WAAW;EACX,gDAAgD;EAChD,4CAA4C;EAC5C,uBAAuB;AACzB;AACA;;EAEE,wBAAwB;EACxB,SAAS,EAAE,uEAAuE;AACpF;;AAEA;EACE,0BAA0B,EAAE,YAAY;AAC1C;AACA;EACE,2CAA2C;EAC3C,uCAAuC;EACvC,YAAY;AACd;AACA;EACE,WAAW;EACX,eAAe;AACjB","sourcesContent":[".login-view {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  margin-top: 2rem;\n}\n.card-login {\n  width: 30rem;\n  padding-bottom: 1rem;\n  border-radius: 10px !important;\n}\n.logo-login {\n  display: flex;\n  justify-content: center;\n  padding: 16px;\n}\n.imgLogin {\n  width: 20rem;\n}\n/* .form-control:focus{\n    color: #212529;\n    background-color: #fff;\n    border-color: var(--color-clicap) !important;\n    outline: 0;\n    box-shadow: 0 0 0 0.1rem var(--color-clicap-focus);\n} */\n\n.btn-login {\n  width: 100%;\n  background-color: var(--color-clicap) !important;\n  border-color: var(--color-clicap) !important;\n  color: white !important;\n}\ninput::-webkit-outer-spin-button,\ninput::-webkit-inner-spin-button {\n  -webkit-appearance: none;\n  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */\n}\n\ninput[type=\"number\"] {\n  -moz-appearance: textfield; /* Firefox */\n}\n.btn-login:hover {\n  background-color: var(--color-clicap-hover);\n  border-color: var(--color-clicap-hover);\n  color: white;\n}\n.btn-register {\n  width: 100%;\n  margin-top: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
