// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.student-certificate-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.student-certificate-card {
  width: 45rem;
  margin: 0.5rem 0rem;
}

@media (max-width: 960px) {
  .student-certificate-card {
    width: 90vw;
    margin: 0.5rem 0rem;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/StudentCertificate/StudentCertificate.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;AACrB;;AAEA;EACE,YAAY;EACZ,mBAAmB;AACrB;;AAEA;EACE;IACE,WAAW;IACX,mBAAmB;EACrB;AACF","sourcesContent":[".student-certificate-container {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n\n.student-certificate-card {\n  width: 45rem;\n  margin: 0.5rem 0rem;\n}\n\n@media (max-width: 960px) {\n  .student-certificate-card {\n    width: 90vw;\n    margin: 0.5rem 0rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
