import React from "react";
import "./ScientificCommittee.css";

export const ScientificCommitte = () => {
  return (
    <>
      <div className="section-header">
        <h2>Comité Científico</h2>
        <p>Investigadores y Profesionales que estarán presente en el evento.</p>
      </div>
      <div className="boxCard centerBoxView mb-5">
        <div className="poderver flex-column container p-3">
          <ul className="flex flex-wrap">
		<li className="listStyle">Ing. Irene Carbajal Ramos</li>
		<li className="listStyle">Mgter. Jorge Sergio de Ondarra</li>
		<li className="listStyle">Dr. Guillermo García</li>
		<li className="listStyle">Mgter. Adriana Beatriz Guajardo</li>
		<li className="listStyle">Dra. María Carolina Martín</li>
		<li className="listStyle">Ing. Antonia Silvana Martinez</li>
		<li className="listStyle">Dra. Verónica Cecilia Musale</li>
		<li className="listStyle">Ing. Heber Noé Possa</li>
		<li className="listStyle">Dra. Luciana Paola Prendes</li>
		<li className="listStyle">Mgter. Antonio Ravalle</li>
		<li className="listStyle">Ing. Eduardo Rodriguez</li>
		<li className="listStyle">Ing. Alicia María Sanchez</li>
		<li className="listStyle">Mgter. Graciela María Serrano</li>
 		<li className="listStyle">Lic. Carlos Rodolfo Vergne</li>
	  </ul>
        </div>
      </div>
    </>
  );
};
