// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sidebar {
  background-color: #2864f6;
  padding-top: 10px;
  border-radius: 0 10px 0 0;
}
.ulSidebar {
  display: flex;
  flex-direction: column;
  font-size: 18px;
  font-weight: 400;
  color: #ffff;
  margin: 2px;
}

.li-box-icon {
  display: flex;
  justify-content: center;
  width: 25px;
  color: #ffff;
}
.li-sidebar {
  padding: 12px 12px 12px 10px;
}
.li-sidebar:hover {
  background-color: #4b7df6;
  border-radius: 5px;
}

.sidebar-activate {
  background-color: #4b7df6;
  border-radius: 5px;
  font-weight: 600;
}

.section-sidebar {
  /* font-weight: 600; */
  font-weight: bold;
  padding-left: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/components/Sidebar/sidebar.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,iBAAiB;EACjB,yBAAyB;AAC3B;AACA;EACE,aAAa;EACb,sBAAsB;EACtB,eAAe;EACf,gBAAgB;EAChB,YAAY;EACZ,WAAW;AACb;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,WAAW;EACX,YAAY;AACd;AACA;EACE,4BAA4B;AAC9B;AACA;EACE,yBAAyB;EACzB,kBAAkB;AACpB;;AAEA;EACE,yBAAyB;EACzB,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,sBAAsB;EACtB,iBAAiB;EACjB,kBAAkB;AACpB","sourcesContent":[".sidebar {\n  background-color: #2864f6;\n  padding-top: 10px;\n  border-radius: 0 10px 0 0;\n}\n.ulSidebar {\n  display: flex;\n  flex-direction: column;\n  font-size: 18px;\n  font-weight: 400;\n  color: #ffff;\n  margin: 2px;\n}\n\n.li-box-icon {\n  display: flex;\n  justify-content: center;\n  width: 25px;\n  color: #ffff;\n}\n.li-sidebar {\n  padding: 12px 12px 12px 10px;\n}\n.li-sidebar:hover {\n  background-color: #4b7df6;\n  border-radius: 5px;\n}\n\n.sidebar-activate {\n  background-color: #4b7df6;\n  border-radius: 5px;\n  font-weight: 600;\n}\n\n.section-sidebar {\n  /* font-weight: 600; */\n  font-weight: bold;\n  padding-left: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
