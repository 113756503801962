// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.importantDate-card {
  display: flex;
  /*   background-color: aqua; */
  color: #ffff;
  border-radius: 10px;
  padding: 1rem;
  background-color: #30A2FF;
  margin: 1rem;
  width: 50%;
}

@media(max-width:1300px){
  .importantDate-card {
    width: 60%;
  }
}

@media(max-width:900px){
  .importantDate-card {
    width: 70%;
  }
}

@media(max-width:600px){
  .importantDate-card {
    width: 90%;
  }
}

.imp-date-title{
    font-size: 1.5rem;
    font-weight: bold;
}

.imp-date-description{
    font-size: 1.25rem;
}

.circle-icon-imp-date {
  border-radius: 100%;
  height: 30px;
  width: 30px;
  /* background-color: #00C4FF; */
}
`, "",{"version":3,"sources":["webpack://./src/views/ImportantDate/importantDate.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,8BAA8B;EAC9B,YAAY;EACZ,mBAAmB;EACnB,aAAa;EACb,yBAAyB;EACzB,YAAY;EACZ,UAAU;AACZ;;AAEA;EACE;IACE,UAAU;EACZ;AACF;;AAEA;EACE;IACE,UAAU;EACZ;AACF;;AAEA;EACE;IACE,UAAU;EACZ;AACF;;AAEA;IACI,iBAAiB;IACjB,iBAAiB;AACrB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;EACE,mBAAmB;EACnB,YAAY;EACZ,WAAW;EACX,+BAA+B;AACjC","sourcesContent":[".importantDate-card {\n  display: flex;\n  /*   background-color: aqua; */\n  color: #ffff;\n  border-radius: 10px;\n  padding: 1rem;\n  background-color: #30A2FF;\n  margin: 1rem;\n  width: 50%;\n}\n\n@media(max-width:1300px){\n  .importantDate-card {\n    width: 60%;\n  }\n}\n\n@media(max-width:900px){\n  .importantDate-card {\n    width: 70%;\n  }\n}\n\n@media(max-width:600px){\n  .importantDate-card {\n    width: 90%;\n  }\n}\n\n.imp-date-title{\n    font-size: 1.5rem;\n    font-weight: bold;\n}\n\n.imp-date-description{\n    font-size: 1.25rem;\n}\n\n.circle-icon-imp-date {\n  border-radius: 100%;\n  height: 30px;\n  width: 30px;\n  /* background-color: #00C4FF; */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
