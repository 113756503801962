// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tooltip > div.tooltip-inner {
  background-color: var(--color-clicap) !important;
  color: white !important;
}

.tooltip.show {
  opacity: 1 !important;
}

.tooltip > div.arrow::before {
  border-bottom-color: red !important;
  color: teal !important;
}

.boxcard-register-responsive {
  width: 70% !important;
}
@media screen and (max-width: 480px) {
  .boxcard-register-responsive {
    width: 90% !important;
  }

  .form-regis-responsive {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
  }
}

.card-top {
  margin-top: 2rem;
}
`, "",{"version":3,"sources":["webpack://./src/components/Register/register.css"],"names":[],"mappings":"AAAA;EACE,gDAAgD;EAChD,uBAAuB;AACzB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,mCAAmC;EACnC,sBAAsB;AACxB;;AAEA;EACE,qBAAqB;AACvB;AACA;EACE;IACE,qBAAqB;EACvB;;EAEA;IACE,aAAa;IACb,eAAe;IACf,sBAAsB;EACxB;AACF;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":[".tooltip > div.tooltip-inner {\n  background-color: var(--color-clicap) !important;\n  color: white !important;\n}\n\n.tooltip.show {\n  opacity: 1 !important;\n}\n\n.tooltip > div.arrow::before {\n  border-bottom-color: red !important;\n  color: teal !important;\n}\n\n.boxcard-register-responsive {\n  width: 70% !important;\n}\n@media screen and (max-width: 480px) {\n  .boxcard-register-responsive {\n    width: 90% !important;\n  }\n\n  .form-regis-responsive {\n    display: flex;\n    flex-wrap: wrap;\n    flex-direction: column;\n  }\n}\n\n.card-top {\n  margin-top: 2rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
