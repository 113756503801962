export const CLEAN_JOB_DATA = "CLEAN_JOB_DATA";
export const EDIT_JOB = "EDIT_JOB";
export const GET_ALL_JOB_VERSIONS = "GET_ALL_JOB_VERSIONS";
export const GET_ALL_JOBS = "GET_ALL_JOBS";
export const GET_IS_OWN_JOB = "GET_IS_OWN_JOB";
export const GET_JOB = "GET_JOB";
export const SET_ASSIGNED_EVALUATOR = "SET_ASSIGNED_EVALUATOR";
export const SET_JOB_LOADER = "SET_JOB_LOADER";
export const SET_JOBS_FILTERS = "SET_JOBS_FILTERS";
export const SET_USERID_TO_JOB = "SET_USERID_TO_JOB";
