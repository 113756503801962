// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.widthConfig {
  width: 50%;
}
.showImg {
  width: 400px;
  height: 300px;
}
.showImgLogo {
  width: 400px;
  height: 120px;
}

@media (max-width: 480px) {
  .widthConfig {
    width: 100%;
  }
}

.boxCard {
  padding: 20px;
  border-radius: 1rem;
  box-shadow: 9px 9px 30px 1px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 9px 9px 30px 1px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 9px 9px 30px 1px rgba(0, 0, 0, 0.2);
  margin-bottom: 1.5rem;
  background-color: #ffff;
}

.centerBox {
  margin: auto;
  margin-top: 10vh;
}
.config-boxs {
  margin: 1rem;
  background-color: #ffff;
  border-radius: 100%;
  height: 120px;
  width: 120px;
  text-align: center;
}
.config-boxs:hover {
  background-color: #19a7ce;
  color: #fff;
}

.centerBox {
  margin: auto;
  margin-top: 10vh;
}
`, "",{"version":3,"sources":["webpack://./src/components/Configuration/configuration.css"],"names":[],"mappings":"AAAA;EACE,UAAU;AACZ;AACA;EACE,YAAY;EACZ,aAAa;AACf;AACA;EACE,YAAY;EACZ,aAAa;AACf;;AAEA;EACE;IACE,WAAW;EACb;AACF;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,+CAA+C;EAC/C,uDAAuD;EACvD,oDAAoD;EACpD,qBAAqB;EACrB,uBAAuB;AACzB;;AAEA;EACE,YAAY;EACZ,gBAAgB;AAClB;AACA;EACE,YAAY;EACZ,uBAAuB;EACvB,mBAAmB;EACnB,aAAa;EACb,YAAY;EACZ,kBAAkB;AACpB;AACA;EACE,yBAAyB;EACzB,WAAW;AACb;;AAEA;EACE,YAAY;EACZ,gBAAgB;AAClB","sourcesContent":[".widthConfig {\n  width: 50%;\n}\n.showImg {\n  width: 400px;\n  height: 300px;\n}\n.showImgLogo {\n  width: 400px;\n  height: 120px;\n}\n\n@media (max-width: 480px) {\n  .widthConfig {\n    width: 100%;\n  }\n}\n\n.boxCard {\n  padding: 20px;\n  border-radius: 1rem;\n  box-shadow: 9px 9px 30px 1px rgba(0, 0, 0, 0.2);\n  -webkit-box-shadow: 9px 9px 30px 1px rgba(0, 0, 0, 0.2);\n  -moz-box-shadow: 9px 9px 30px 1px rgba(0, 0, 0, 0.2);\n  margin-bottom: 1.5rem;\n  background-color: #ffff;\n}\n\n.centerBox {\n  margin: auto;\n  margin-top: 10vh;\n}\n.config-boxs {\n  margin: 1rem;\n  background-color: #ffff;\n  border-radius: 100%;\n  height: 120px;\n  width: 120px;\n  text-align: center;\n}\n.config-boxs:hover {\n  background-color: #19a7ce;\n  color: #fff;\n}\n\n.centerBox {\n  margin: auto;\n  margin-top: 10vh;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
