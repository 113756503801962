import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import gallery1 from "../../assets/gallery/1.jpg";
import gallery2 from "../../assets/gallery/2.jpg";
import gallery3 from "../../assets/gallery/3.jpg";
import gallery4 from "../../assets/gallery/4.jpg";
import gallery5 from "../../assets/gallery/5.jpg";
import gallery6 from "../../assets/gallery/6.jpg";
import gallery7 from "../../assets/gallery/7.jpg";
import gallery8 from "../../assets/gallery/8.jpg";
import gallery9 from "../../assets/gallery/9.jpg";
import gallery10 from "../../assets/gallery/10.jpg";
import gallery11 from "../../assets/gallery/11.jpg";
import gallery12 from "../../assets/gallery/12.jpg";
import gallery13 from "../../assets/gallery/13.jpg";
import gallery14 from "../../assets/gallery/14.jpg";
import gallery15 from "../../assets/gallery/15.jpg";
import gallery16 from "../../assets/gallery/16.jpg";
import gallery17 from "../../assets/gallery/17.jpg";
import gallery18 from "../../assets/gallery/18.jpg";
import gallery19 from "../../assets/gallery/19.jpg";
import gallery20 from "../../assets/gallery/20.jpg";
import gallery21 from "../../assets/gallery/21.jpg";
import gallery22 from "../../assets/gallery/22.jpg";
import gallery23 from "../../assets/gallery/23.jpg";
import gallery24 from "../../assets/gallery/24.jpg";
import gallery25 from "../../assets/gallery/25.jpg";
import gallery26 from "../../assets/gallery/26.jpg";
import gallery27 from "../../assets/gallery/27.jpg";
import gallery28 from "../../assets/gallery/28.jpg";
import gallery29 from "../../assets/gallery/29.jpg";
import gallery30 from "../../assets/gallery/30.jpeg";
import gallery31 from "../../assets/gallery/31.jpeg";
import gallery32 from "../../assets/gallery/32.jpeg";
import "./Carousel.css";

const Carousel = () => {
  const photos = [
    gallery1,
    gallery2,
    gallery3,
    gallery4,
    gallery5,
    gallery6,
    gallery7,
    gallery8,
    gallery9,
    gallery10,
    gallery11,
    gallery12,
    gallery13,
    gallery14,
    gallery15,
    gallery16,
    gallery17,
    gallery18,
    gallery19,
    gallery20,
    gallery21,
    gallery22,
    gallery23,
    gallery24,
    gallery25,
    gallery26,
    gallery27,
    gallery28,
    gallery29,
    gallery30,
    gallery31,
    gallery32,
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <>
      <Slider {...settings}>
        {photos.map((photo, index) => (
          <div key={index}>
            <img
              src={photo}
              alt={`Photo ${index + 1}`}
              className="carouselBox"
            />
          </div>
        ))}
      </Slider>
    </>
  );
};

export default Carousel;
