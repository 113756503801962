import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router";
import { evaluateDate, getDataUserByKey } from "../../../helpers/helpers";
import { JobContext } from "../../../context/Job/JobContext";
import { AppContext } from "../../../context/App/AppContext";
import JobStudentList from "./JobStudentList";
import { PaginationCustom } from "../../Pagination/Pagination";
import { Loader } from "../../Loader/Loader";

const JobStudent = () => {
  const navigate = useNavigate();
  const idUser = getDataUserByKey("id");

  const initialFilters = { userId: idUser, name: "", areaId: "" };
  const { getJobsFiltered, jobState, cleanJobData } = useContext(JobContext);
  const { isFetching, jobs, totalJobsPages, jobData } = jobState;
  const { appState, getEventDate } = useContext(AppContext);
  const { eventDate, deadlineDays, isLoadingEventDate } = appState;

  const [filters, setFilters] = useState(initialFilters);
  const [page, setPage] = useState(1);

  useEffect(() => {
    getJobsFiltered(page, filters);
    getEventDate();
    if (jobData.name !== "") cleanJobData();
  }, [page]);

  useEffect(() => {
    console.log("Event Date: ", eventDate);
    console.log("Deadline Days: ", deadlineDays);
    console.log("isLoadingEventDate: ", isLoadingEventDate);
  }, [eventDate, deadlineDays, isLoadingEventDate]);

  const isOnTime = evaluateDate(eventDate, deadlineDays);

  return (
    <div className="ms-3 me-3">
      <h2 className="text-center">Mis trabajos</h2>
      <div className="box-add-instance">
        <div className="text-end me-5">
          <button
            type="button"
            onClick={() => navigate("/newjob")}
            className="btn btn-success"
            disabled={isLoadingEventDate || !isOnTime} // Deshabilita el botón mientras se cargan los datos
          >
            <i className="fa-solid fa-plus"></i> Nuevo trabajo
          </button>
        </div>
      </div>
      <div className="mt-3 overflow-x">
        <table className="table table-hover">
          <thead>
            <tr>
              <th>Título</th>
              <th>Area</th>
              <th>Modalidad</th>
              <th>Estado</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {jobs && jobs.map((job) => <JobStudentList job={job} key={job.id} />)}
          </tbody>
        </table>
      </div>
      {isFetching && (
        <div className="center-center">
          <Loader />
        </div>
      )}
      {jobs.length > 0 ? (
        <PaginationCustom
          currentPage={page}
          totalPages={totalJobsPages}
          paginate={setPage}
        />
      ) : (
        <p className="mt-4 text-center">No hay Trabajos</p>
      )}
    </div>
  );
};

export default JobStudent;

