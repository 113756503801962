// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sidebarPhone-box {
  background-color: #fff;
  height: 100vh;
}
/* 
.show-menu-phone {
  display: block;
}
.show-menu-desktop {
  display: none;
}

@media screen and (min-width: 480px) {
  .show-menu-phone {
    display: none;
  }
  .show-menu-desktop {
    display: block;
  }
} */
`, "",{"version":3,"sources":["webpack://./src/components/MenuPhone/menuPhone.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,aAAa;AACf;AACA;;;;;;;;;;;;;;;GAeG","sourcesContent":[".sidebarPhone-box {\n  background-color: #fff;\n  height: 100vh;\n}\n/* \n.show-menu-phone {\n  display: block;\n}\n.show-menu-desktop {\n  display: none;\n}\n\n@media screen and (min-width: 480px) {\n  .show-menu-phone {\n    display: none;\n  }\n  .show-menu-desktop {\n    display: block;\n  }\n} */\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
