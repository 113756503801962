// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.display-flex {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.contactBox {
  text-align: center;
  width: 30%;
}

@media (max-width: 960px) {
  .contactBox {
    text-align: center;
    width: auto;
  }
}

.boxCardContact {
  padding: 20px;
  border-radius: 1rem;
  box-shadow: 9px 9px 30px 1px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 9px 9px 30px 1px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 9px 9px 30px 1px rgba(0, 0, 0, 0.2);
  margin-bottom: 1.5rem;
}

.sendEmailContact {
  padding: 20px;
  border-radius: 1rem;
  margin-bottom: 1.5rem;
}

.centerBoxContact {
  margin: 0 auto;
  width: 70%;
  background-color: #ffff;
}

@media (max-width: 960px) {
  .centerBoxContact {
    width: 80%;
  }
}

.iFrame {
  border: 0;
  width: 100%;
  height: 350px;
  margin-bottom: 2rem;
}
`, "",{"version":3,"sources":["webpack://./src/views/Contact/contact.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,eAAe;EACf,6BAA6B;AAC/B;;AAEA;EACE,kBAAkB;EAClB,UAAU;AACZ;;AAEA;EACE;IACE,kBAAkB;IAClB,WAAW;EACb;AACF;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,+CAA+C;EAC/C,uDAAuD;EACvD,oDAAoD;EACpD,qBAAqB;AACvB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,qBAAqB;AACvB;;AAEA;EACE,cAAc;EACd,UAAU;EACV,uBAAuB;AACzB;;AAEA;EACE;IACE,UAAU;EACZ;AACF;;AAEA;EACE,SAAS;EACT,WAAW;EACX,aAAa;EACb,mBAAmB;AACrB","sourcesContent":[".display-flex {\n  display: flex;\n  flex-wrap: wrap;\n  justify-content: space-around;\n}\n\n.contactBox {\n  text-align: center;\n  width: 30%;\n}\n\n@media (max-width: 960px) {\n  .contactBox {\n    text-align: center;\n    width: auto;\n  }\n}\n\n.boxCardContact {\n  padding: 20px;\n  border-radius: 1rem;\n  box-shadow: 9px 9px 30px 1px rgba(0, 0, 0, 0.2);\n  -webkit-box-shadow: 9px 9px 30px 1px rgba(0, 0, 0, 0.2);\n  -moz-box-shadow: 9px 9px 30px 1px rgba(0, 0, 0, 0.2);\n  margin-bottom: 1.5rem;\n}\n\n.sendEmailContact {\n  padding: 20px;\n  border-radius: 1rem;\n  margin-bottom: 1.5rem;\n}\n\n.centerBoxContact {\n  margin: 0 auto;\n  width: 70%;\n  background-color: #ffff;\n}\n\n@media (max-width: 960px) {\n  .centerBoxContact {\n    width: 80%;\n  }\n}\n\n.iFrame {\n  border: 0;\n  width: 100%;\n  height: 350px;\n  margin-bottom: 2rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
