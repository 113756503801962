import React from "react";

export const PageInProcess=()=> {
  return (
    <div className="container">
      <div className="section-header">
        <h2>Página en proceso...</h2>
        <p>Estamos trabajando en ella.</p>
      </div>
    </div>
  );
}
